import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

// Custom Component
import ErrorAlert from "../Alert/ErrorAlert";

// Utility Service
import { updateUserSession, setCookieToken } from "../../utils/AuthService";

// API Service
import { getCustomerProfileService } from "../../service/profile.service";

// Redux-Functions
import { setToken, setUser } from "../../redux/userSlice";

const TokenTracker = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    /**
        * @function fetchUserInfo
        * @params
        * @description fetch the info of user
    */
    const fetchUserInfo = async () => {
        try {
            const result = await getCustomerProfileService();
            if (result?.data?.status) {
                dispatch(setUser({
                    ...result.data?.data,
                    type: 'customer'
                }))
                updateUserSession({
                    ...result.data?.data,
                    type: 'customer'
                })
                if (location?.pathname === '/sso') {
                    navigate('/');
                }
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (searchParams?.get("result") === "success" && searchParams?.get("token")) {
            setCookieToken(searchParams?.get("token"))
            dispatch(setToken(searchParams?.get("token")))
            fetchUserInfo()
        } else if (location?.pathname === '/sso') {
            ErrorAlert('Something went wrong')
        }
        // eslint-disable-next-line
    }, [location, searchParams])

    return null;
};

export default TokenTracker;
