import { Row, Col, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { CiMail, CiLocationOn, CiCalendar } from "react-icons/ci";
import { BsTelephone } from "react-icons/bs";
import classNames from "classnames";
import moment from 'moment';

// Images
import { logo } from '../../assets/images';
// import { Avatar, User, Users, Men, Women } from '../../assets/images/svg';
import { User, Users, Men, Women } from '../../assets/images/svg';

// Redux-Functions
import { selectUser } from "../../redux/userSlice"

// Utils
import { convertEventType, convertAgeGroup, convertGender } from '../../utils/helper';

const Receipt = (props) => {
    const location = useLocation();

    const user = useSelector(selectUser);

    const numbersToWords = {
        0: "zero",
        1: "one",
        2: "two",
        3: "three",
        4: "four",
        5: "five",
        6: "six",
        7: "seven",
        8: "eight",
        9: "nine",
        10: "ten",
        11: "eleven",
        12: "twelve",
        13: "thirteen",
        14: "fourteen",
        15: "fifteen",
        16: "sixteen",
        17: "seventeen",
        18: "eighteen",
        19: "nineteen",
        20: "twenty",
        30: "thirty",
        40: "forty",
        50: "fifty",
        60: "sixty",
        70: "seventy",
        80: "eighty",
        90: "ninety"
    }

    let event_details;

    if (props?.tournament?.player_details) {
        event_details = props?.tournament?.category_type === "INDIVIDUAL" ? [...new Set(props?.tournament?.player_details)] : props?.tournament?.player_details?.length > 0 ? [...new Set(props?.tournament?.player_details[0]?.team_event_type)] : []
    } else if (props?.tournament?.players) {
        event_details = props?.tournament?.category_type === "INDIVIDUAL" ? [...new Set(props?.tournament?.players)] : props?.tournament?.players?.length > 0 ? [...new Set(props?.tournament?.players[0]?.team_event_type)] : []
    }

    /**
         * @function convertNumberToWords
         * @params number
         * @description used to convert number to words
         */
    const convertNumberToWords = (number) => {
        // if number present in object no need to go further
        if (number in numbersToWords) return numbersToWords[number];

        // Initialize the words variable to an empty string
        let words = "";

        // If the number is greater than or equal to 100, handle the hundreds place (ie, get the number of hundres)
        if (number >= 100) {
            // Add the word form of the number of hundreds to the words string
            words += convertNumberToWords(Math.floor(number / 100)) + " hundred";

            // Remove the hundreds place from the number
            number %= 100;
        }

        // If the number is greater than zero, handle the remaining digits
        if (number > 0) {
            // If the words string is not empty, add "and"
            if (words !== "") words += " and ";

            // If the number is less than 20, look up the word form in the numbersToWords object
            if (number < 20) words += numbersToWords[number];
            else {
                // Otherwise, add the word form of the tens place to the words string
                //if number = 37, Math.floor(number /10) will give you 3 and 3 * 10 will give you 30
                words += numbersToWords[Math.floor(number / 10) * 10];

                // If the ones place is not zero, add the word form of the ones place
                if (number % 10 > 0) {
                    words += "-" + numbersToWords[number % 10];
                }
            }
        }

        // Return the word form of the number
        return words;
    }

    return <div className={classNames('mt-vh-100', { 'd-none': !props?.pdfContentShow })}>
        <div className="d-flex flex-column pb-5 px-4" ref={props?.pdfRef}>
            <div className='bg-secondary bg-opacity-10 px-4 py-5'>
                <div className="d-flex flex-column align-items-start w-100">
                    <span className='fs-30 fw-600 mb-3'>Receipt</span>
                    <img className='img-fluid mb-4' src={logo} alt='AFA' />
                    <span className='fs-48 fw-600'>{props?.tournament?.organizer?.name}</span>
                    <div className="mt-2">
                        <CiLocationOn className="me-2" size={20} />
                        <span>{props?.tournament?.organizer?.address}</span>
                    </div>
                    <Row className="w-100 mt-2">
                        <Col xl={7}>
                            <CiMail className="me-2" size={20} />
                            <span className="fw-500">{props?.tournament?.organizer?.email}</span>
                        </Col>
                        <Col xl={5} className='mt-2 mt-xl-0 d-none d-xl-flex justify-content-xl-end align-items-start'>
                            <span className={classNames({
                                'me-0-point-3': location?.pathname?.includes("tournament"),
                                'me-0-point-4': !location?.pathname?.includes("tournament")
                            })}>Date</span>
                            {[...Array(13)].map((x, i) => {
                                return <span className='d-none d-xl-block' key={i}>&nbsp;</span>
                            })}
                            <div>
                                <span className="me-2">:</span>
                                <span>{moment(new Date()).format("DD/MM/YYYY")}</span>
                            </div>
                            {location?.pathname?.includes("tournament") ?
                                <>
                                    {[...Array(25)].map((x, i) => {
                                        return <span className='d-none d-xl-block' key={i}>&nbsp;</span>
                                    })}
                                </>
                                :
                                <>
                                    {[...Array(25)].map((x, i) => {
                                        return <span className={classNames('d-none d-xl-block', {
                                            'me-0-point-3': i === 0
                                        })} key={i}>&nbsp;</span>
                                    })}
                                </>
                            }
                        </Col>
                    </Row>
                    <Row className="w-100 mt-2">
                        <Col xl={7}>
                            <BsTelephone className="me-2" size={20} />
                            <span>{props?.tournament?.organizer?.phone}</span>
                        </Col>
                        <Col xl={5} className='mt-2 mt-xl-0 d-flex d-xl-none justify-content-xl-end align-items-start'>
                            <span className='me-0-point-4'>Date</span>
                            {[...Array(11)].map((x, i) => {
                                return <span key={i}>&nbsp;</span>
                            })}
                            <div>
                                <span className="ms-2 ms-xl-0 me-2">:</span>
                                <span>{moment(new Date()).format("DD/MM/YYYY")}</span>
                            </div>
                        </Col>
                        <Col xl={5} className='mt-2 mt-xl-0 d-flex justify-content-xl-end align-items-start'>
                            <span className='me-0-point-4'>Receipt no</span>
                            {[...Array(2)].map((x, i) => {
                                return <span className='d-none d-xl-block' key={i}>&nbsp;</span>
                            })}
                            <div>
                                <span className="ms-2 ms-xl-0 me-2">:</span>
                                {props?.tournament?.player_details?.length > 0 &&
                                    <span>{props?.tournament?.player_details[0]?.receipt_no}</span>
                                }
                                {props?.tournament?.players?.length > 0 &&
                                    <span>{props?.tournament?.players[0]?.receipt_no}</span>
                                }
                            </div>
                            {[...Array(2)].map((x, i) => {
                                return <span className={classNames('d-none d-xl-block', {
                                    'me-0-point-5': i === 0
                                })} key={i}>&nbsp;</span>
                            })}
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='px-4'>
                <div className="d-flex flex-column align-items-start my-4">
                    <span className="fs-24 fw-600 mb-3">Tournament details</span>
                    <span className="fs-18 fw-600 mb-3">{props?.tournament?.name}</span>
                    <div>
                        <CiCalendar className="me-2" size={20} />
                        <span>{moment(new Date(props?.tournament?.tournament_start_date)).format("MM/DD/YYYY")} to {moment(new Date(props?.tournament?.tournament_end_date)).format("MM/DD/YYYY")}</span>
                    </div>
                    <div className="mt-2">
                        <BsTelephone className="me-2" size={20} />
                        <span>{props?.tournament?.phone_number}</span>
                    </div>
                    <div className="mt-2">
                        <CiLocationOn className="me-2" size={20} />
                        <span>{props?.tournament?.address}</span>
                    </div>
                </div>
                <hr />
                <div className="d-flex flex-column align-items-start mt-3">
                    <span className="fs-24 fw-600 mb-4">Booking details</span>
                    <span className="fs-20 fw-600 mb-3">Badminton</span>
                    {props?.tournament?.category_type === "INDIVIDUAL" &&
                        <>
                            <div className="mb-3">
                                <User className='me-2' />
                                <span>Individuals Event</span>
                            </div>
                            <span>Type of Event</span>
                            <div className='d-flex flex-wrap mb-3'>
                                {event_details?.length > 0 && event_details?.slice(0, 1)?.map((item, index) => {
                                    return <div className='d-flex align-items-center bg-primary text-light mt-2 me-2 p-2 rounded' key={index}>
                                        {(item?.event_type === 'MEN_SINGLE' || item?.event_type === 'MEN_DOUBLE' || item?.event_type === 'MIX_DOUBLE' || item?.event_type === 'MEN_3_3' || item?.event_type === 'MIX_3_3' || item?.event_type === 'JUNIOR_SINGLE' || item?.event_type === 'JUNIOR_DOUBLE') && <Men color='white' className='me-2' />}
                                        {(item?.event_type === 'WOMEN_SINGLE' || item?.event_type === 'WOMEN_DOUBLE' || item?.event_type === 'MIX_DOUBLE' || item?.event_type === 'WOMEN_3_3' || item?.event_type === 'MIX_3_3' || item?.event_type === 'JUNIOR_SINGLE' || item?.event_type === 'JUNIOR_DOUBLE') && <Women color='white' className='me-2' />}
                                        <span>
                                            {convertEventType(item?.event_type)}
                                            {item?.age_group && item?.age_value && ` (${convertAgeGroup(item?.age_group, item?.age_value)})`}
                                        </span>
                                    </div>
                                })}
                            </div>
                        </>
                    }
                    {props?.tournament?.category_type === "TEAM" &&
                        <>
                            <div className="mb-3">
                                <Users className="me-2" size={20} />
                                <span>Team Event</span>
                            </div>
                            <span>Type of Event</span>
                            <div className='d-flex flex-wrap mb-3'>
                                {event_details?.length > 0 && event_details?.map((item, index) => {
                                    return <div className='d-flex align-items-center bg-primary text-light mt-2 me-2 p-2 rounded' key={index}>
                                        {(item === 'MEN_SINGLE' || item === 'MEN_DOUBLE' || item === 'MIX_DOUBLE' || item === 'MEN_3_3' || item === 'MIX_3_3' || item === 'JUNIOR_SINGLE' || item === 'JUNIOR_DOUBLE') && <Men color='white' className='me-2' />}
                                        {(item === 'WOMEN_SINGLE' || item === 'WOMEN_DOUBLE' || item === 'MIX_DOUBLE' || item === 'WOMEN_3_3' || item === 'MIX_3_3' || item === 'JUNIOR_SINGLE' || item === 'JUNIOR_DOUBLE') && <Women color='white' className='me-2' />}
                                        <span>
                                            {convertEventType(item)}
                                            {props?.tournament?.player_details?.length > 0 &&
                                                ` (${props?.tournament?.player_details[0]?.team_event_age_group?.length > 0 && props?.tournament?.player_details[0]?.team_event_age_value?.length > 0 && convertAgeGroup(props?.tournament?.player_details[0]?.team_event_age_group[index], props?.tournament?.player_details[0]?.team_event_age_value[index])})`
                                            }
                                            {props?.tournament?.players?.length > 0 &&
                                                ` (${props?.tournament?.players[0]?.team_event_age_group?.length > 0 && props?.tournament?.players[0]?.team_event_age_value?.length > 0 && convertAgeGroup(props?.tournament?.players[0]?.team_event_age_group[index], props?.tournament?.players[0]?.team_event_age_value[index])})`
                                            }
                                        </span>
                                    </div>
                                })}
                            </div>
                        </>
                    }
                    <span className='mb-2'>Players ({props?.tournament?.player_details?.length || props?.tournament?.players?.length})</span>
                    <Row className='w-100 mx-0 d-xl-none'>
                        {props?.tournament?.player_details?.length > 0 && props?.tournament?.player_details?.map((item, index) => {
                            return <Col xs={12} className='d-flex flex-column align-items-start border rounded mb-3 p-3'>
                                <span className='fs-18'>{index + 1}</span>
                                <span className='fs-24 fw-600 text-capitalize mb-2'>{item?.player_name}</span>
                                <span className='fs-18 mb-2'>{convertGender(item?.player_gender)}</span>
                                <span className='fs-18'>{item?.player_email}</span>
                                <span>{item?.player_contact}</span>
                            </Col>
                        })}
                        {props?.tournament?.players?.length > 0 && props?.tournament?.players?.map((item, index) => {
                            return <Col xs={12} className='d-flex flex-column align-items-start border rounded mb-3 p-3'>
                                <span className='fs-18'>{index + 1}</span>
                                <span className='fs-24 fw-600 text-capitalize mb-2'>{item?.player_name}</span>
                                <span className='fs-18 mb-2'>{convertGender(item?.player_gender)}</span>
                                <span className='fs-18'>{item?.player_email}</span>
                                <span>{item?.player_contact}</span>
                            </Col>
                        })}
                    </Row>

                    <Table className='mt-2 mb-5 border table-fixed d-none d-xl-inline-table'>
                        <thead>
                            <tr className='text-secondary'>
                                <th className='bg-secondary bg-opacity-10 text-truncate receipt-number-header'>No.</th>
                                <th className='bg-secondary bg-opacity-10 text-truncate'>Player name</th>
                                <th className='bg-secondary bg-opacity-10 text-truncate'>Players Gender</th>
                                <th className='bg-secondary bg-opacity-10 text-truncate'>Email address</th>
                                <th className='bg-secondary bg-opacity-10 text-truncate'>Phone number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props?.tournament?.player_details?.length > 0 && props?.tournament?.player_details?.map((item, index) => {
                                return <tr key={index}>
                                    <td className='text-truncate'>{index + 1}</td>
                                    <td className='text-capitalize text-truncate'>{item?.player_name}</td>
                                    <td className='text-truncate'>{convertGender(item?.player_gender)}</td>
                                    <td className='text-truncate'>{item?.player_email}</td>
                                    <td className='text-truncate'>{item?.player_contact}</td>
                                </tr>
                            })}
                            {props?.tournament?.players?.length > 0 && props?.tournament?.players?.map((item, index) => {
                                return <tr key={index}>
                                    <td className='text-truncate'>{index + 1}</td>
                                    <td className='text-capitalize text-truncate'>{item?.player_name}</td>
                                    <td className='text-truncate'>{convertGender(item?.player_gender)}</td>
                                    <td className='text-truncate'>{item?.player_email}</td>
                                    <td className='text-truncate'>{item?.player_contact}</td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                </div>
                <div className="d-flex flex-column align-items-start">
                    <span className="fs-24 fw-600 mb-4">User Details</span>
                    <div className='d-flex align-items-center mb-2'>
                        {/* {user?.type === 'customer' && user?.profile_pic ?
                            <img className='profile-small-photo rounded-circle me-2' src={user?.profile_pic} alt={user?.username || user?.name}/>
                            :
                            user?.type === 'organizer' && props?.tournament?.player_details?.length > 0 ?
                                <img className='profile-small-photo rounded-circle me-2' src={props?.tournament?.player_details[0]?.customer?.profile_pic} alt={props?.tournament?.player_details[0]?.customer?.username} />
                                :
                                <Avatar className="me-2" size={30} />
                        } */}
                        <div className='d-flex flex-column align-items-start'>
                            <span className='fw-600'>{user?.type === 'customer' ? user?.username : props?.tournament?.player_details?.length > 0 && props?.tournament?.player_details[0]?.customer?.username ? props?.tournament?.player_details[0]?.customer?.username : props?.tournament?.players?.length > 0 && props?.tournament?.players[0]?.customer?.username ? props?.tournament?.players[0]?.customer?.username : user?.name}</span>
                            <span>{user?.type === 'customer' ? user?.country?.code + ' ' + user?.phone : props?.tournament?.player_details?.length > 0 && props?.tournament?.player_details[0]?.customer?.country?.code && props?.tournament?.player_details[0]?.customer?.phone ? props?.tournament?.player_details[0]?.customer?.country?.code + ' ' + props?.tournament?.player_details[0]?.customer?.phone : props?.tournament?.players?.length > 0 && props?.tournament?.players[0]?.customer?.country?.code && props?.tournament?.players[0]?.customer?.phone ? props?.tournament?.players[0]?.customer?.country?.code + ' ' + props?.tournament?.players[0]?.customer?.phone : user?.country?.code + ' ' + user?.phone}</span>
                        </div>
                    </div>
                    <span className='fs-14 fw-500'>{user?.type === 'customer' ? user?.email : props?.tournament?.player_details?.length > 0 && props?.tournament?.player_details[0]?.customer ? props?.tournament?.player_details[0]?.customer?.email : props?.tournament?.players?.length > 0 && props?.tournament?.players[0]?.customer ? props?.tournament?.players[0]?.customer?.email : user?.email}</span>
                    <span className='fs-14 fw-500'>{user?.type === 'customer' ? convertGender(user?.gender) : (user?.type === 'organizer' && ((props?.tournament?.player_details?.length > 0 && props?.tournament?.player_details[0]?.customer?.gender))) ? convertGender(props?.tournament?.player_details[0]?.customer?.gender) : (user?.type === 'organizer' && ((props?.tournament?.players?.length > 0 && props?.tournament?.players[0]?.customer?.gender))) ? convertGender(props?.tournament?.players[0]?.customer?.gender) : convertGender(user?.gender)}</span>
                    <span className='fs-14 fw-500'>{user?.type === 'customer' ? user?.country?.name : props?.tournament?.player_details?.length > 0 && props?.tournament?.player_details[0]?.customer ? `${props?.tournament?.player_details[0]?.customer?.country?.name || ""}` : props?.tournament?.players?.length > 0 && props?.tournament?.players[0]?.customer ? `${props?.tournament?.players[0]?.customer?.country?.name || ""}` : user?.country?.name}</span>
                    <div className='d-flex flex-column w-100 align-items-start mt-3'>
                        <span className='fs-18 fw-600 me-4'>Total Price paid</span>
                        <span className='fs-12 me-4'>Amount Chargeable (in words)</span>
                        {props?.tournament?.category_type === "INDIVIDUAL" &&
                            <div className='d-flex justify-content-between align-items-center w-100 fs-18 fw-600'>
                                {props?.tournament?.player_details &&
                                    <>
                                        <span className='me-4 text-capitalize'>Ringgit Malaysia {convertNumberToWords(parseInt(props?.tournament?.player_details[0]?.event_price))} Only</span>
                                        <span>RM{parseFloat(props?.tournament?.player_details[0]?.event_price).toFixed(2)}</span>
                                    </>
                                }
                                {props?.tournament?.players?.length > 0 &&
                                    <>
                                        <span className='me-4 text-capitalize'>Ringgit Malaysia {convertNumberToWords(parseInt(props?.tournament?.players[0]?.event_price))} Only</span>
                                        <span>RM{parseFloat(props?.tournament?.players[0]?.event_price).toFixed(2)}</span>
                                    </>
                                }
                            </div>
                        }
                        {props?.tournament?.category_type === "TEAM" &&
                            <div className='d-flex justify-content-between align-items-center w-100 fs-18 fw-600'>
                                <span className='me-4 text-capitalize'>Ringgit Malaysia {convertNumberToWords(parseInt(props?.tournament?.team_event_price))} Only</span>
                                <span>RM{parseFloat(props?.tournament?.team_event_price).toFixed(2)}</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default Receipt;